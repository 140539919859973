export default {
  data() {
    return {
      startDateKey: "startDateKey",
      endDateKey: "endDateKey",
      radioKey: "rdok",
      showTransation: false,
      transactionTBkey: "",
      filterSelected: {},
    }
  },
  mounted() {
    this.lodash.appendRouteQuery(this.$router, {
      query: this.lodash.omit(this.$route.query, "object_id_selected"),
    });
  },
  watch: {
    filter: {
      immediate: true,
      deep: true,
      handler(filter) {
        if (filter.start_day > filter.end_day) {
          this.filter = {
            ...this.filter,
            start_day: this.lodash.toTimeStamp(filter.end_day * 1000, "start"),
            end_day: this.lodash.toTimeStamp(filter.start_day * 1000, "end"),
          };
          this.startDateKey = this.lodash.resetKey("startDateKey");
          this.endDateKey = this.lodash.resetKey("endDateKey");
        }
      },
    },
    queryRoute: {
      immediate: true,
      deep: true,
      handler(query) {
        if (!this.lodash.isEmpty(query)) {
          this.filter = {
            ...query,
            start_day: +query.start_day || this.lodash.toTimeStamp(Date.now(), "start"),
            end_day: +query.end_day || this.lodash.toTimeStamp(Date.now(), "end"),
          };
        }
      },
    },
    "filter.walletable_type": {
      immediate: true,
      handler() {
        this.filter.walletable_id = "";
      },
    },
  },
  computed: {
    list() {
      return this.$store.getters[this.store + ".list"]
    },
    detail() {
      return this.$store.getters[this.store + ".detail"]
    },
    loading() {
      return this.$store.getters[this.store + ".loading"]
    },

    selected() {
      return {
        [this.keySelected]: this.queryRoute[this.keySelected],
      };
    },
    queryRoute() {
      return this.$route.query || {};
    },
  },
  methods: {
    clearFilter() {
      this.lodash.appendRouteQuery(this.$router, { query: {} });
      this.filter = {
        currency_id: "",
        start_day: this.lodash.toTimeStamp(Date.now(), "start"),
        end_day: this.lodash.toTimeStamp(Date.now(), "end"),
        walletable_type: "",
      };
      this.showTransation = false;
      this.radioKey = this.lodash.resetKey("radioKey");
    },
    changeWalletableType(type) {
      this.filter.walletable_type = type;
      this.filter.walletable_id = "";
    },
    exportExcel() {
      this.$store
        .dispatch("helper.exports.export-transactions", {
          id: this.id,
          "filter_between[voucher_date]": `${this.filterSelected.start_day},${this.filterSelected.end_day}`,
          "filter[currency_id]": this.filterSelected.currency_id,
          "filter[debit_or_credit]":
            this.filterSelected?.account_id || this.detail?.account_id || "",
          "filter[transactionable_id]": this.selected?.object_id_selected || "",
          "filter[card_id]": this.selected?.id || "",
        })
        .then((data) => {
          const intlDate = new Intl.DateTimeFormat("en-GB");
          const startDateFm = intlDate.format(
            new Date(1000 * this.filterSelected.start_day)
          );
          const endDateFm = intlDate.format(
            new Date(1000 * this.filterSelected.end_day)
          );
          const name =
            this.detail?.object?.name ||
            this.detail?.walletable_id ||
            this.detail?.transactionable_id ||
            this.detail?.name;
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(data);
          link.download = `Transactions-${name}-${this.detail?.currency_id}-${startDateFm}-${endDateFm}.xlsx`;
          link.click();
        });
    },
  },
  destroyed() {
    this.$store.commit(this.store + ".purge");
  },
}
