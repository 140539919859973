<template>
  <CRow class="mb-5">
    <CCol lg="4" xl="3">
      <TAside class="card">
        <CListGroup>
          <CListGroupItem>
            <CRow>
              <CCol col="12" lg="12" xl="6">
                <TMessage content="From date" bold class="mb-2" />
                <TInputDateTime
                  :value.sync="filter.start_day"
                  timeline="start"
                  placeholder="Start date"
                  class="mb-2"
                  :key="startDateKey"
                />
              </CCol>
              <CCol col="12" lg="12" xl="6">
                <TMessage content="To date" bold class="mb-2" />
                <TInputDateTime
                  :value.sync="filter.end_day"
                  timeline="end"
                  placeholder="End date"
                  class="mb-2"
                  :key="endDateKey"
                />
              </CCol>
              <CCol col="12">
                <SSelectCurrency
                  :value.sync="filter.currency_id"
                  label="Currency"
                  :prepend="[]"
                  class="mb-2"
                />
              </CCol>
            </CRow>
            <hr />
            <CRow>
              <CCol col="12">
                <TInputRadio
                  label="Object"
                  :list="walletable_types"
                  :value.sync="filter.walletable_type"
                  @update:value="changeWalletableType"
                  custom
                  :key="radioKey"
                  class="h-auto"
                />
                <SSelectCustomer
                  v-if="filter.walletable_type == 'user'"
                  :value.sync="filter.walletable_id"
                  label="Customer"
                  class="mb-2"
                />
                <SSelectSupplier
                  v-if="filter.walletable_type == 'supplier'"
                  :value.sync="filter.walletable_id"
                  label="Supplier"
                  class="mb-2"
                />
              </CCol>

              <CCol col="12" class="d-flex mt-2">
                <TButton
                  class="w-100"
                  content="Filter"
                  @click="onFilter"
                  :disabled="loading || !filter.end_day || !filter.start_day"
                />
                <TButtonClear
                  @click="clearFilter"
                  variant="outline"
                  class="ml-1"
                  size=""
                />
              </CCol>
            </CRow>
          </CListGroupItem>
          <CListGroupItem v-if="loading">
            <TSpinner :loading="loading" />
          </CListGroupItem>
          <CListGroupItem
            v-for="(object, index) in list"
            :key="`${index}`"
            class=""
            :color="
              selected.object_id_selected == object.walletable_id
                ? 'primary'
                : 'secondary'
            "
            :to="
              !loading
                ? _.getAppendRouteQuery($route, {
                    object_id_selected: object.walletable_id,
                  })
                : ''
            "
          >
            <div class="d-flex">
              <SMessageWallet :data="object" />
              <TMessageMoney
                :amount="object.balance"
                class="ml-auto"
                :currency="object.currency_id"
              />
            </div>
          </CListGroupItem>
          <CListGroupItem v-if="!list.length && !loading">
            <TMessageNotFound />
          </CListGroupItem>
          <SButtonLoadmore
            v-if="!loading && list.length"
            store="accounting.statistics_accounting_period"
            class="mt-2 mb-3"
          />
        </CListGroup>
      </TAside>
    </CCol>
    <CCol lg="8" xl="9">
      <CCard v-if="!lodash.isEmpty(detail) && selected.object_id_selected">
        <CCardBody>
          <CRow class="mb-2">
            <CCol class="d-flex align-items-center">
              <SMessageWallet :data="detail" />
              <TButton
                content="Export excel"
                icon="cid-cloud-download"
                size="sm"
                @click="exportExcel"
                class="ml-auto"
                :disabled="export_loading"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol col="12" lg="5">
              <CRow>
                <CCol col="12" class="mb-2">
                  <TWidgetSimple
                    title="Opening balance"
                    color="primary"
                    class="m-0"
                    alignment="left"
                  >
                    <template #content>
                      <TMessageMoney
                        :amount="detail.opening_balance"
                        :currency="filterSelected.currency_id"
                        color="primary"
                        class="h3"
                      />
                    </template>
                  </TWidgetSimple>
                </CCol>
                <CCol col="12" class="mb-2">
                  <TWidgetSimple
                    title="During balance"
                    color="primary"
                    alignment="left"
                    class="m-0"
                    :number="detail.during_balance"
                  >
                    <template #content>
                      <TMessageMoney
                        :amount="detail.during_balance"
                        :currency="filterSelected.currency_id"
                        color="primary"
                        class="h3"
                      />
                    </template>
                  </TWidgetSimple>
                </CCol>
                <CCol col="12" class="mb-2">
                  <TWidgetSimple
                    title="Closing balance"
                    color="primary"
                    alignment="left"
                    class="m-0"
                    :number="detail.closing_balance"
                  >
                    <template #content>
                      <TMessageMoney
                        :amount="detail.closing_balance"
                        :currency="filterSelected.currency_id"
                        color="primary"
                        class="h3"
                      />
                    </template>
                  </TWidgetSimple>
                </CCol>
              </CRow>
            </CCol>
            <CCol col="12" lg="7">
              <CListGroup class="scroll-y" style="max-height: 320px">
                <CListGroupItem
                  v-for="(type, index) in tatistics_transaction_types"
                  :key="`${index}`"
                >
                  <div class="d-flex align-items-center">
                    <SMessageTransactionType
                      v-if="type.type"
                      :value="type.type.id"
                      class="mb-0 h6"
                    />
                    <TMessageMoney
                      :amount="type.amount"
                      class="ml-auto"
                      :currency="type.currency_id"
                    />
                  </div>
                </CListGroupItem>
              </CListGroup>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>

      <TableTransactions
        v-if="
          !lodash.isEmpty(detail) &&
          showTransation &&
          selected.object_id_selected
        "
        :key="transactionTBkey"
        :showVoucherDateFilter="false"
        store="accounting.transactions"
        class="mb-4"
        showReference
        :default_filter="{
          'filter[transactionable_id]': selected.object_id_selected,
          'filter[currency_id]': filterSelected.currency_id,
          'filter_between[voucher_date]': `${filterSelected.start_day},${filterSelected.end_day}`,
          sort: '-id',
          append: 'object,wallet,references',
          include: 'preparedBy',
        }"
      />
    </CCol>
  </CRow>
</template>

<script>
import { mapGetters } from "vuex";
import TableTransactions from "../../components/TableTransactions.vue";
import mixin from "./mixin";

export default {
  components: { TableTransactions },
  mixins: [mixin],
  data() {
    return {
      filter: {
        currency_id: "",
        start_day: this.lodash.toTimeStamp(Date.now(), "start"),
        end_day: this.lodash.toTimeStamp(Date.now(), "end"),
        walletable_type: "",
      },
      walletable_types: [
        { value: "user", label: this.$t("Customer") },
        { value: "supplier", label: this.$t("Supplier") },
      ],
      store: "accounting.statistics_accounting_period",
      keySelected: "object_id_selected",
    };
  },
  watch: {
    "queryRoute.object_id_selected": {
      immediate: true,
      deep: true,
      handler(newId, oldId) {
        if (oldId != newId) {
          this.showTransation = true;
          const objSelected = this.list.find((x) => x.walletable_id == newId);
          if (objSelected) {
            this.$store.commit(
              "accounting.statistics_accounting_period.select",
              objSelected.id
            );
            this.fetchTransactionTypes();
            this.transactionTBkey = this.lodash.resetKey("transactionTBkey");
          }
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      tatistics_transaction_types:
        "accounting.statistics_transaction_types.list",
      export_loading: "helper.exports.loading",
    }),
  },
  methods: {
    appendRoute() {
      this.lodash.appendRouteQuery(this.$router, {
        query: {
          ...this.$route.query,
          currency_id: this.filter?.currency_id,
          walletable_id: this.filter?.walletable_id || "",
          walletable_type: this.filter?.walletable_type,
          start_day: this.filter?.start_day.toString(),
          end_day: this.filter?.end_day.toString(),
        },
      });
    },
    onFilter() {
      this.appendRoute();
      this.fetchPeriod(this.filter);
      this.filterSelected = this.filter;
    },
    fetchTransactionTypes() {
      if (this.selected.object_id_selected) {
        const filterFields = this.lodash.pickBy({
          ...this.lodash.omit(this.filterSelected, "object_id_selected"),
          walletable_id: this.selected.object_id_selected,
        });
        this.$store
          .dispatch(
            "accounting.statistics_transaction_types.apply-query",
            filterFields
          )
          .catch(() => {
            this.$store.commit("accounting.statistics_transaction_types.purge");
          });
      }
    },
    clearFilter() {
      this.lodash.appendRouteQuery(this.$router, {
        query: {
          ...this.lodash.pick(this.$route.query, ["object_id_selected"]),
        },
      });
      this.filter = {
        currency_id: "",
        start_day: this.lodash.toTimeStamp(Date.now(), "start"),
        end_day: this.lodash.toTimeStamp(Date.now(), "end"),
        walletable_type: "",
      };
      this.radioKey = this.lodash.resetKey("radioKey");
    },
    fetchPeriod(filter) {
      this.$store
        .dispatch("accounting.statistics_accounting_period.apply-query", {
          start_date: filter.start_day || "0",
          end_date: filter.end_day,
          "filter[currency_id]": filter.currency_id,
          "filter[walletable_type]": filter.walletable_type,
          "filter[walletable_id]": filter.walletable_id,
          include: "object",
        })
        .then(() => {
          const objSelected = this.list.find(
            (x) => x.walletable_id == this.detail?.walletable_id
          );
          if (objSelected) {
            this.transactionTBkey = this.lodash.resetKey("transactionTBkey");
            this.$store.commit(
              "accounting.statistics_accounting_period.detail.merge",
              objSelected
            );
            this.fetchTransactionTypes();
          }
        });
    },
  },
  destroyed() {
    this.$store.commit("accounting.statistics_transaction_types.purge");
  },
};
</script>
